:root {
  --lp-colors-medium-blue-600: #0016D1;
  --lp-colors-medium-blue-800: #000E8F;
  --lp-colors-turquoise-600: #43E3FF;
}

.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-12,
.col-md-4,
.col-md-6,
.col-md-8,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}
.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }

  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%
  }

  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
  .text-md-left {
    text-align: left !important;
  }
}

.text-left {
  text-align: left !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pb-1, .py-1 {
  padding-bottom: 4px !important;
}

.pb-2, .py-2 {
  padding-bottom: 8px !important;
}

.pb-3, .py-3 {
  padding-bottom: 16px !important;
}

.pb-4, .py-4 {
  padding-bottom: 24px !important;
}

.pt-1, .py-1 {
  padding-top: 4px !important;
}

.pt-2, .py-2 {
  padding-top: 8px !important;
}

.pt-3, .py-3 {
  padding-top: 16px !important;
}

.pt-4, .py-4 {
  padding-top: 24px !important;
}

.pr-3, .px-3 {
  padding-right: 16px;
}

.pl-3, .px-3 {
  padding-left: 16px;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 24px !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.mb-3 {
  margin-bottom: 16px !important;
}

.mb-4, .my-4 {
  margin-bottom: 24px !important;
}

.ml-0, .mx-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

@media (min-width: 1200px) {
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0;
  }

  .pt-xl-1, .pt-xl-1 {
    padding-top: 4px;
  }

  .text-xl-right {
    text-align: right;
  }
}

@media (min-width: 768px) {
  .pt-md-1, .py-md-1 {
    padding-top: 4px !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: 11.2px !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 16px !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: 8px !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 16px !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 576px) {
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 16px !important;
  }
  .pt-sm-5, .py-sm-5 {
    padding-top: 48px !important;
  }
  .pr-sm-2, .px-sm-2 {
    padding-right: 8px !important;
  }
  .pl-sm-2, .px-sm-2 {
    padding-left: 8px !important;
  }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .pl-m {
    padding-left: 50px;
  }
}

@media (max-width: 570px) {
  .pl-m {
    padding-left: 50px;
  }
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.lp-box {
  background-color: #fff;
  padding: 31px 29px 28px;
  border: 1px #e7e7e7 solid;
  -webkit-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.50);
  -moz-box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.50);
  box-shadow: 0.5px 0.5px 3px 0.6px rgba(220, 227, 235, 0.50);
  margin-bottom: 20px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .lp-box {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
  }
}

a {
  color: var(--lp-colors-medium-blue-600);
  text-decoration: none;
  background-color: transparent;
  font-weight: 300;
}
a:hover{
  text-decoration: underline;
  cursor: pointer;
  color: var(--lp-colors-medium-blue-800);
}

* {
  box-sizing: border-box;
  font-family: "Geograph", Arial, sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
}
