.header-title {
  .row {
    margin: auto;
  }
  &.lp-dashboard-top {
    padding: 33px 15px 12px 22px;
    position: relative;

    .title {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 400;
      margin: 0;
      color: #000;
      font-family: 'Geograph Bold';
    }

    @media (min-width: 1200px) {
      padding: 33px 15px 10px 60px;
    }

    @media (max-width: 960px) {
      padding: 23px 15px 0 64px;
    }

    @media (max-width: 768px) {
      background-color: #fff;

      .title-block {
        margin-bottom: 14px;
      }

      .title {
        font-size: 21px;
      }
    }
  }
}



