.settings-general {
  &.content {
    padding: 30px 22px 45px;
  }

  .lp-nav{
    padding: 0px;
  }

  @media (min-width: 1200px) {
    .lp-dashboard-top {
      padding: 33px 60px 30px;
    }

    .content {
      padding: 18px 60px 25px;
    }
  }

  .lp-details {
    color: #000;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    &.content {
      padding: 0 0 45px 0;
    }
  }

  @media (min-width: 1200px) {
    &.content {
      padding: 18px 60px 25px;
    }
  }

  .row {
    @media (max-width: 768px) {
      display: block;
    }
  }
}