@font-face {
  font-family: 'Geograph';
  src: url('assets/fonts/geograph-web-regular.eot');
  src: url('assets/fonts/geograph-web-regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/geograph-web-regular.woff2') format('woff2'),
  url('assets/fonts/geograph-web-regular.woff') format('woff')
}

@font-face {
  font-family: 'Geograph Bold';
  src: url('assets/fonts/geograph-web-medium.eot');
  src: url('assets/fonts/geograph-web-medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/geograph-web-medium.woff2') format('woff2'),
  url('assets/fonts/geograph-web-medium.woff') format('woff')
}
