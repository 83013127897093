.fixed-tabs {
  .lp-nav {
    position: relative;
    padding: 0;

    .nav-item {
      padding: 4px 8px;
      margin-right: 8px;
      color: #555C7C;

      &.active {
        border-bottom: 2px var(--lp-colors-medium-blue-600) solid;
        color: #323750;
      }

      .nav-link {
        border-radius: 0;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        cursor: pointer;
        color: inherit;
      }
    }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    margin: 16px 0;
  }

  .nav-item {
    position: relative;
  }

  @media (max-width: 768px) {
    .lp-nav .nav {
      border-bottom: 0;
      margin: 0;
      padding: 0 20px;
    }
  }

  @media (max-width: 570px) {
    .lp-nav {
      background-color: #fcfcfc;
      position: relative;
      width: 100%;
      height: 46px;
      overflow: hidden;
      white-space: nowrap;

      ul {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      .nav-item {
        padding-right: 21px;
      }

      li {
        float: left;
      }
    }
  }
}