.back-component {
  &.lp-dashboard-top {
    padding: 0px 37px 10px ;
    position: relative;

    .title {
      font-size: 16px;
      line-height: 1.2;
      margin: 0;
      color: #6C7488;
      cursor: pointer;
      text-decoration: none;
      display: flex;
    }
    .arrow-left {
      width: 20px;
      height: 20px;
    }
    .title-text {
      margin-left: 5px;
      font-size: 14px;
      line-height: 16px;
      color: #555C7C;
    }
    .row {
      margin: auto;
    }

    @media (min-width: 1200px) {
      padding: 0px 75px 10px;
    }

    @media (max-width: 960px) {
      padding: 23px 22px 0 63px;
    }

    @media (max-width: 768px) {
      .title-block {
        margin-bottom: 14px;
      }

      .title {
        font-size: 12px;
      }
    }
  }
}



